import React from 'react';
import './app.css'

class App extends React.Component {
    render() {
        return (
            <div className="app ui container">
                <br/>
                <h2>Custom software development</h2>
                <br/>
                <h3>Email: info@divito.pro</h3>
                <h3>Phone: +371 2774 8668</h3>
                <br/>
            </div>
        );
    }
}

export default App;
